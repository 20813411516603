import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

const ArticleLink = styled(Link).attrs((props) => ({
  className: 'text-base font-medium text-darkBlue hover:text-darkBlue my-4',
}))`
  flex-direction: row;
  display: flex;
  align-items: center;
  width: fit-content;
  &:hover {
    box-shadow: inset 0 -30px 0 0 ${({ theme }) => theme.colors.accentYellow};
  }
  svg {
    margin-right: 6px;
    width: calc(3 * var(--lns-unit, 8px));
    height: calc(3 * var(--lns-unit, 8px));
  }
`;

const ReadLink = ({ to, text, hideRight }) => (
  <ArticleLink to={to}>
    {hideRight ? null : (
      <svg viewBox="0 0 24 24" fill="none">
        <path
          d="M18.791 11.005H7.621l4.88-4.88c.39-.39.39-1.03 0-1.42a.996.996 0 00-1.41 0l-6.59 6.59a.996.996 0 000 1.41l6.59 6.59a.996.996 0 101.41-1.41l-4.88-4.88h11.17c.55 0 1-.45 1-1s-.45-1-1-1z"
          fill="currentColor"
        />
      </svg>
    )}
    {text}
  </ArticleLink>
);

ReadLink.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.node,
  hideRight: PropTypes.bool,
};
// add  transition ease-in-out duration-150 for quicker transition
export default ReadLink;
