import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@components/Divider';
import Container from '@containers/Container';
import {
  SectionContainer,
  SectionHeader,
  SectionTitle,
  SectionContent,
  SectionExtraInfo,
} from './Section.styled';

const Section = ({
  children,
  sectionTitle,
  extraHeaderInfo,
  isBlog,
  hasNoDivider,
}) => (
  <Container>
    <SectionContainer>
      {!isBlog ? (
        <>
          <SectionHeader>
            <SectionTitle>{sectionTitle}</SectionTitle>
            <SectionExtraInfo>{extraHeaderInfo}</SectionExtraInfo>
          </SectionHeader>
          {hasNoDivider ? null : <Divider margin="0 0 30px 0" />}
        </>
      ) : null}
      <SectionContent>{children}</SectionContent>
    </SectionContainer>
  </Container>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
  extraHeaderInfo: PropTypes.node,
  sectionTitle: PropTypes.string,
  isBlog: PropTypes.bool,
  hasNoDivider: PropTypes.bool,
};

export default Section;
