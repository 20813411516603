import React from 'react';
import PropTypes from 'prop-types';

const IFrame = ({ iframe, className }) => (
  <div
    className={className}
    dangerouslySetInnerHTML={{ __html: iframe || '' }}
  />
);

IFrame.propTypes = {
  iframe: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default IFrame;
