import styled from 'styled-components';
import { widthFrom, widthUntil } from '@theme/theme';

const SectionContainer = styled.section``;

const SectionHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const SectionExtraInfo = styled.div`
  ${widthUntil.TABLET`
      align-self: center;
  `}
`;

const SectionTitle = styled.h1`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSizes.heading};
  line-height: ${({ theme }) => theme.lineHeight.heading};
  margin: ${({ margin }) => margin || 0};
  color: ${({ theme }) => theme.colors.themeColor};
  ${widthUntil.TABLET`
    font-size: ${({ theme }) => theme.fontSizes.mobileHeading};
  `}
`;

const SectionContent = styled.div``;

export {
  SectionContainer,
  SectionHeader,
  SectionTitle,
  SectionContent,
  SectionExtraInfo,
};
